<template>
  <div class="bofangChater">
    <div class="box-item"><i class="listIcon"></i>课程列表</div>
    <el-collapse @change="onChnage" accordion v-model="openIndex">
      <el-collapse-item :name="grounpIndex" v-for="(grounp, grounpIndex) in CourseList" :key="grounpIndex">
        <template slot="title">
          <div v-if="grounpIndex == $route.query.openIndex && !changeItem" class="isBofangs"></div>
          <span class="ellipsis"> {{ grounp.title }}</span>
          <span class="audition" v-if="grounp.audition && !IsPurchase">可试听</span>
        </template>
        <div class="chat-item" v-for="(item, index) in grounp.list" :key="index">
          <div
            class="item-title"
            :class="playid == item.id ? 'active' : ''"
            @click.stop="check(item, 1, grounpIndex)"
          >
            <!-- 播放图片 -->
            <div v-if="playid == item.id" class="isbofang"></div>
            <!-- <i v-else class="iconfont icon-a-Union21"></i> -->
            <div class="title w-b-100 ellipsis" :class="{ ispadding: playid != item.id }">
              {{ item.title }}
            </div>
            <!-- <div v-if="playid == item.id" class="title ellipsis shrink0">
              正在播放
            </div> -->
            <!-- <div class="video iconfont icon-a-Union21" @click.stop="goLive(item, item.isLive)"></div> -->
          </div>
          <!-- 产品线 1 普通线 2 精品线  3 专属线 4 普通线加精品线  5 1v1 考研班型 -->
          <div class="but-list" v-if="$route.query.classProductLine !== '1'">
            <div class="keep-learn" v-if="item.isHasContantJY == 'true'" @click="ktData(item, 5)">
              随堂讲义
            </div>
            <div class="keep-learn" v-if="item.isHasContant == 'true'" @click="cgeckTask(item)">随堂测试</div>
            <div class="keep-learn" v-if="item.isHasDoc == 'true'" @click="ktData(item, 3)">随堂资料</div>
            <!--  <div class="keep-learn" @click.stop="check(item, 1)">观看课程</div>
            <div class="keep-learn" @click="cgeckTask(item)" v-if="item.isHasContant == 'true'">课后作业</div>
            <div class="keep-learn" @click="ktData(item)" v-if="item.isHasDoc == 'true'">随堂资料</div> -->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="warpCpons" @touchmove.stop.prevent="stop()" v-if="dialogVisible">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click="tChuang" />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span
          >观看<span>{{ lookDeatil ? lookDeatil.chapterName : '' }}</span
          >,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getClassCourseById } from '@/api/home'
import { IsLive } from '@/api/emun'
import { getInfo, getToken } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import { courseCheckPlay } from '@/api/know'
let answerClient = new AnswerClient()
export default {
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      openIndex: this.$route.query.openIndex ? +this.$route.query.openIndex : 0,
      index: 0,
      checkItem: {},
      changeItem: true,
      lookDeatil: null,
      showLookDetail: false,
    }
  },
  props: ['id', 'playid', 'list', 'IsPurchase', 'classId', 'classProductLine', 'dot', 'typekc'],
  created() {
    this.userInfo = getInfo()
    if (this.list) {
      this.CourseList = this.list
      for (let item of this.CourseList) {
        if (!item.list) {
          item.list = item.videoList
        }
      }
      this.CourseList.forEach((item, index) => {
        item.chapter = item.list.map((i) => {
          return i.id
        })
        if (item.chapter.includes(Number(this.playid))) {
          this.index = index
        }
      })
      // if (this.index && this.index != 0) {
      //   this.CourseList.unshift(this.CourseList[this.index]);
      //   this.CourseList.splice(this.index + 1, 1);
      // }
    } else {
      getClassCourseById(
        this.id,
        this.classId && this.typekc != 16 ? this.classId : undefined,
        this.classId && this.typekc == 16 ? this.classId : undefined
      ).then((res) => {
        this.CourseList = res.data.details.list
        for (let item of this.CourseList) {
          item.audition = false
          for (let chil of item.list) {
            if (chil.audition && chil.audition != '0') {
              item.audition = true
              break
            }
          }
        }
      })
    }
  },
  mounted() {
    
    let bofangChater = document.querySelector('.bofangChater')
    if (this.dot) {
      bofangChater.style.height = '720px'
    } else {
      bofangChater.style.height = '556px'
    }
  },
  methods: {
    onChnage(name) {
      this.changeItem = name
   
    },
    tChuang() {
      this.dialogVisible = false
    },
    stop() {
      return
    },
    //观看回放
    check(item, groupId, index) {
      // 可试听
      if (item.audition != '0' && !this.IsPurchase && item.audition != '' && item.audition != null) {
        let sub = {
          id: item.id,
          uservideoConfig: item.videoConfig,
          courseId: this.id,
          classId: this.classId,
          groupId: groupId.id,
          typebs: 2,
          classProductLine: this.classProductLine,
          chapterName: item.title,
          typekc: this.typekc,
        }
        let data = Vue.prototype.export(sub)
        this.$router.push(`/dianbofang?${data}&openIndex=${index}`)
        return
      }
      this.checkItem = item
     
      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase && (!item.audition || item.audition == '0')) {
        this.$notify({
          title: '错误',
          message: '未购买，无法学习, 请先去购买',
          type: 'error',
          duration: 2000,
        })
        return
      }
      let sub = {
        id: item.id,
        uservideoConfig: item.videoConfig,
        courseId: this.id,
        classId: this.classId,
        groupId: groupId.id,
        typebs: 2,
        classProductLine: this.classProductLine,
        chapterName: item.title,
        typekc: this.typekc,
      }
      let data = Vue.prototype.export(sub)
      this.$router.push(`/dianbofang?${data}&openIndex=${index}`)
      // courseCheckPlay({
      //   courseType: "2",
      //   userId: this.userInfo.id,
      //   chapterId: item.id,
      //   source: 1,
      // }).then((res) => {
      //   if (res.code == 0) {
      //   } else {
      //     this.lookDeatil = res.msg;
      //     this.showLookDetail = true;
      //   }
      // });
    },
    // 课堂作业
    cgeckTask(item) {
      answerClient.selectPaperSubjectList(item.sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            item.title,
            item.sId,
            2,
            3,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId
          )
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    //随堂资料
    ktData(item, type) {
      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, '_blank')
      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
  },
}
</script>
<style lang="less" scoped>
.bofangChater {
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  // height: 100%;
  height: 556px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 100%;
  .isBofangs {
    width: 14px;
    height: 14px;
    background: url('~@/assets/img/kecheng/bofang.png') no-repeat top left / 100% 100%;
    margin-right: 10px;
  }
  .isbofang {
    display: inline-block;
    width: 16px;
    height: 14px;
    background: url('~@/assets/img/kecheng/bofang.png') no-repeat top left / 100% 100%;
    margin-right: 14px;
  }
  .box-item {
    padding: 0px 20px;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: 48px;
    background: #444444;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    .listIcon {
      width: 16px;
      height: 16px;
      background: url('~@/assets/img/kecheng/listIcon.png') no-repeat top left / 100% 100%;
      margin-right: 6px;
    }
    .iconfont {
      font-size: 16px;
      margin-right: 3px;
    }
    margin-bottom: 4px;
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
/deep/ .el-collapse {
  border: none !important;
  .el-collapse-item__header {
    padding: 0px 20px;
    min-height: 48px;
    height: auto;
    background: #555555;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 48px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    margin-top: 4px;
  }
  .el-collapse-item__content {
    padding: 0px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
  flex-shrink: 0;
}
/* 节 */
.chat-item {
  width: 100%;
  border-bottom: 1px solid #555555;
  padding: 16px 20px;
  background: #383838;
  .active {
    color: #ff5e51;
    .iconfont,
    .title {
      color: #ff5e51 !important;
    }
  }
  .item-title {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      color: #ffffff;
      flex-shrink: 0;
    }
    .ispadding {
      margin-left: 32px;
    }
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    .keep-learn {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 22px;
      margin-left: 12px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #d2d2d2;
      font-size: 10px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      padding: 0 !important;
    }
  }
}

::v-deep .el-collapse-item__arrow {
  transform: rotate(90deg) !important;
}
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(-90deg) !important;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
