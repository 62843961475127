<template>
  <div class="wrap">
    <div class="bigBlock">
      <div class="iframeWrap">
        <div class="rTag blue top-200">
          <div class="iconfont icon-mulu"></div>
        </div>
        <div class="rTag brown top-264">
          <div class="iconfont icon-jiaocaixiangqing"></div>
        </div>
        <div class="rTag green top-328">
          <div class="iconfont icon-kechengxiangqing"></div>
        </div>
        <div class="lTag">购买套餐</div>
        <div class="fullScreen iconfont icon-quanping1"></div>
        <div class="dualScreen iconfont icon-shuangping"></div>
        <div class="leftArrow">
          <div class="iconfont icon-xiangyou"></div>
        </div>
        <div class="rightArrow">
          <div class="iconfont icon-xiangzuo"></div>
        </div>
        <div></div>
        <iframe src="" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bodyWidth: null,
      bodyHeight: null,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.wrap {
  .bigBlock {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #282b33;
    .iframeWrap {
      width: 646px;
      height: 937px;
      background-color: white;
      margin: auto;
      position: relative;
      .rTag {
        position: absolute;
        left: -60px;
        width: 60px;
        height: 44px;
        border-radius: 24px 0 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
      .lTag {
        position: absolute;
        top: 130px;
        right: -120px;
        width: 120px;
        height: 44px;
        border-radius: 0 24px 24px 0;
        background-color: #b23535;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
      }
      .top-200 {
        top: 200px;
      }
      .top-264 {
        top: 264px;
      }
      .top-328 {
        top: 328px;
      }
      .blue {
        background-color: #356fb2;
      }
      .brown {
        background-color: #b27135;
      }
      .green {
        background-color: #308e61;
      }
      .fullScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 334px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
      }
      .dualScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 373px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
      }
      .leftArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 533px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
      }
      .rightArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 612px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
      }
    }
  }
}
</style>